<template>
  <!-- Layout Header -->
  <a-layout-header class="layout-header" :style="{ height: device.mobile ? '50px' : '74px' }">
    <a-row>
      <a-col :span="24">
        <div class="letwecare-breadcrumb">
          <a-breadcrumb
            v-if="!device.mobile"
            class="__breadcrumb"
            :routes="breadcrumbRoutes"
          >
            <template #itemRender="{ routes, route }">
              <span v-if="routes.indexOf(route) === routes.length - 1">
                {{ route.breadcrumbName }}
              </span>
              <router-link v-else :to="route.path">
                {{ route.breadcrumbName }}
              </router-link>
            </template>
          </a-breadcrumb>

          <a-page-header
            class="__page-header"
            style="height: 63px; padding-left: 0;"
            :title="`${courseData.name}：班級公告`"
            @back="backToCoursePage"
          >
            <template #itemRender="{ route, params, routes, paths }">
              <span v-if="routes.indexOf(route) === routes.length - 1">{{route.breadcrumbName}}</span>
              <router-link v-else :to="paths.join('/')">{{route.breadcrumbName}}</router-link>
            </template>
          </a-page-header>
        </div>
      </a-col>
    </a-row>
  </a-layout-header>
  <!-- Layout Header -->

  <!-- Layout Body -->
  <a-layout class="app-layout announcement-layout">
    <div class="__wrapper">
      <a-card>
        <div class="mb-3">共有 {{announcementsData.length}} 則班級公告</div>

        <div class="announcement__body">
          <template v-if="!loading && announcementsData.length">
            <a-list
              class="comment-list"
              :data-source="announcementsData"
              item-layout="horizontal"
            >
              <template #renderItem="{ item }">
                <a-list-item>
                  <a-comment
                    class="comment-item"
                    :author="`${item.info_doc.name} (${RoleUtil.toString(item.role).zh})`"
                    :avatar="item.info_doc.avatar.uri"
                    :content="item.content"
                    :datetime="DateUtil.formatDatetime(item.created_datetime)"
                  >
                    <template #actions>
                      <template v-if="item.likes.length > 0">
                        <LikeFilled
                          @click="openLikeUsersDrawer(item.likes)"
                          :style="{ fontSize: '1rem', color: '#FF8900' }"
                        />
                      </template>
                      <template v-else>
                        <LikeOutlined :style="{ fontSize: '1rem' }" />
                      </template>
                      <span :style="{ fontSize: '1rem', paddingLeft: '2px', color: item.likes.length ? '#FF8900':'' }">
                        {{ item.likes.length }}
                      </span>
                    </template>
                  </a-comment>
                </a-list-item>
              </template>
            </a-list>
          </template>

          <template v-if="!loading && !announcementsData.length">
            <a-empty :image="simpleImage" description="公告空空的" />
          </template>
        </div>

        <div class="announcement__actions">
          <a-comment>
            <template #avatar>
              <a-avatar
                :src="authStore.info.avatar!=null ? authStore.info.avatar.uri : require('@/assets/icons/avatar.png')"
                alt="avatar"
              />
            </template>
            <template #content>
              <a-form-item class="mb-3">
                <a-textarea
                  v-model:value="formState.content"
                  :rows="device.mobile ? 3 : 4"
                  :disabled="submitting"
                  :style="{ fontSize: '1rem' }"
                />
              </a-form-item>
              <a-form-item class="mb-0">
                <a-button
                  @click="insertOneAnnouncement"
                  :loading="submitting"
                  html-type="submit"
                  type="primary"
                >
                  新增公告
                </a-button>
              </a-form-item>
            </template>
          </a-comment>
        </div>
      </a-card>
    </div>
  </a-layout>
  <!-- Layout Body -->

  <!-- Like Users Drawer -->
  <a-drawer
    v-model:visible="likeUsersDrawer.visible"
    title="點讚成員"
    placement="right"
  >
    <a-table
      :dataSource="likeUsersDrawer.likes"
      :columns="likeUsersDrawerColumns"
      size="small"
      :pagination="false"
      :scroll="{ x: 'max-content' }"
    >
      <template #bodyCell="{ column, text, record }">
        <template v-if="column.key === 'name'">
          {{ record.user.name }}
        </template>

        <template v-if="column.key === 'created_datetime'">
          {{ DateUtil.formatDatetime(record.created_datetime) }}
        </template>
      </template>
    </a-table>
  </a-drawer>
  <!-- Like Users Drawer -->
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { message, Empty } from 'ant-design-vue'
import { createDeviceDetector } from 'next-vue-device-detector'
import { LikeFilled, LikeOutlined } from '@ant-design/icons-vue'
import { useAuthStore } from '@/store/auth'
import api from '@/apis'
import RoleUtil from '@/utils/RoleUtil'
import DateUtil from '@/utils/DateUtil'

const likeUsersDrawerColumns = [
  // {
  //   title: '學員編號',
  //   dataIndex: 'member_id',
  //   key: 'member_id'
  // },
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '時間',
    dataIndex: 'created_datetime',
    key: 'created_datetime'
  }
]


export default defineComponent({
  components: {
    LikeFilled,
    LikeOutlined
  },
  setup() {
    const route = useRoute();
    const device = createDeviceDetector();

    /* Store */
    const authStore = useAuthStore();

    /* Loading */
    const loading = ref(false);
    const submitting = ref(false);

    /* Data */
    const courseData = ref({});
    const announcementsData = ref([]);

    /* Form State */
    const initialState = {
      content: ''
    }
    const formState = ref({});
    formState.value = { ...initialState };

    /* Api */
    const getCourseData = (async () => {
      const response = await api.v1.course.findOne(route.params.course_id);
      courseData.value = response.data.result;
      console.log(courseData.value);
    });

    const getAnnouncementsData = (async () => {
      announcementsData.value = [];

      const response = await api.v1.course.announcementList(route.params.course_id);
      announcementsData.value = response.data.result;

      console.log(announcementsData.value);
    });

    const insertOneAnnouncement = (async () => {
      submitting.value = true;

      try {
        await api.v1.course.insertOneAnnouncement(route.params.course_id, {
          author_id: authStore.info.uid,
          content: formState.value.content
        });
        formState.value = { ...initialState };

        message.success('新增公告成功');
        getAnnouncementsData();
      } catch (error) {
        message.success('新增公告發生錯誤，請稍後再試');
      } finally {
        submitting.value = false;
      }
    });

    /* Routes breadcrumb */
    const breadcrumbRoutes = ref([
      {
        path: '/app/courses',
        breadcrumbName: '班級管理'
      }
    ]);

    /* Mounted */
    onMounted(async () => {
      loading.value = true;

      await getCourseData();
      await getAnnouncementsData();

      // set routes breadcrumb
      breadcrumbRoutes.value.push({
        path: `/app/course/${ route.params.course_id }`,
        breadcrumbName: courseData.value.name
      });
      breadcrumbRoutes.value.push({
        path: `/app/course/${ route.params.course_id }/announcement`,
        breadcrumbName: '班級公告'
      });

      loading.value = false;
    });

    return {
      device,

      /* Store */
      authStore,

      /* Routes breadcrumb */
      breadcrumbRoutes,

      /* Loading */
      loading,
      submitting,

      /* Data */
      courseData,
      announcementsData,

      /* Form State */
      formState,
      
      /* Like Users Drawer */
      likeUsersDrawerColumns,

      /* Api */
      insertOneAnnouncement,

      /* Utils */
      RoleUtil,
      DateUtil,

      /* Empty */
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    }
  },
  data() {
    return {
      /* Like Users Drawer */
      likeUsersDrawer: {
        visible: false,
        likes: []
      }
    }
  },
  methods: {
    /* Router */
    backToCoursePage() {
      this.$router.push({
        name: 'AppCoursePage',
        params: {
          course_id: this.$route.params.course_id
        }
      });
    },

    /* Like Users Drawer */
    openLikeUsersDrawer(likes) {
      this.likeUsersDrawer.likes = likes;
      this.likeUsersDrawer.visible = true;
    }
  }
})
</script>

<style lang="scss">
.announcement-layout {
  overflow: auto;

  .__wrapper {
    width: 100%;
    max-width: 960px;
    margin: 0 auto 0 auto;

    /* md */
    @media screen and (min-width: 970px) {
      padding: 20px;
    }
  }

  .announcement__body {
    height: calc(100% - 200px - 30px);
    overflow: auto;

    .comment-list {
      .ant-list-item {
        padding-top: 0 !important;
      }

      .comment-item {
        white-space: pre-wrap;
        margin-bottom: 0;

        .ant-comment-content-detail {
          font-size: 1rem;
        }
      }
    }
  }

  .announcement__actions {
    height: 200px;
    border-top: 1px solid #E0E0E0;
    margin-top: 12px;
    padding-top: 12px;
  }
}
</style>