export default class RoleUtil {
  static MEMBER = 1;
  static COACH = 2;
  static ASSISTANT = 3;
  static ADMIN = 4;

  /**
  * @desc 取得所有roles
  * @return {object-array} 所有roles
  */
  static list() {
    const roles = [{
      id: this.MEMBER,
      roleNameEN: 'member',
      roleNameCH: '會員',
    }, {
      id: this.COACH,
      roleNameEN: 'coach',
      roleNameCH: '教練',
    }, {
      id: this.ASSISTANT,
      roleNameEN: 'assistant',
      roleNameCH: '助教',
    }, {
      id: this.ADMIN,
      roleNameEN: 'admin',
      roleNameCH: '管理員',
    }]
    return roles
  }

  /**
  * @desc 數字role轉為字串
  * @param role {int}
  * @return {object} 字串身份 {en, zh}
  */
  static toString(role: number) {
    let result = {
      en: '',
      zh: ''
    }
    
    switch (role) {
      case this.MEMBER:
        result = {
          en: 'member',
          zh: '會員'
        }
        break;
      case this.COACH:
        result = {
          en: 'coach',
          zh: '教練'
        }
        break;
      case this.ASSISTANT:
        result = {
          en: 'assistant',
          zh: '助教'
        }
        break;
      case this.ADMIN:
        result = {
          en: 'admin',
          zh: '管理員'
        }
        break;
    }

    return result
  }
}